import { nanoid } from "nanoid";

// TEAM MEMBER DATA
export const teamData = [
  {
    id: nanoid(),
    fullName: "Andra Rush",
    bio:
      "Is founder, chair and CEO of the Rush Group of Companies. Rush Group is one of the largest Native American-owned and woman-owned enterprises in the U.S. President Barack Obama acknowledged Rush during his 2014 State of the Union address for creating hundreds of manufacturing jobs in Detroit with the June 2012 opening of Detroit Manufacturing Systems – the first such plant opening in the city of Detroit in decades. In the first 36 months of operation, Rush led DMS to $1 billion in annual revenue and 1,000 team members. Andra has received numerous accolades, including Ford Motor Company’s World Excellence Award for Diverse Supplier and Ford Q1 quality endorsement for the Detroit plant, the University of Michigan’s Ann Arbor alumna Michigan Women’s Hall of Fame in 2014, the Michigan Business Women Hall of Fame, and the Michigan Minority Business Hall of Fame in 2017.",
    group: "board",
  },
  {
    id: nanoid(),
    fullName: "Bart Flaherty",
    bio:
      "Bart is a data scientist, marketing technologist, and entrepreneur with 25 years experience across the spectrum of industries. My partners and I are currently focused on artificial intelligence, predictive algorithms, and natural language processing. We have built some innovative tools. This is an exciting time …. particularly for brands facing new business models with e-commerce.",
    group: "board",
  },
  {
    id: nanoid(),
    fullName: "Brad Koepenick",
    bio:
      "Koepenick is Co-Founder and President of SparkRise. He is an Actor/Writer/Producer who has appeared in Films (Sandtrap, The Distinguished Gentleman, Albino Alligator, Fright Night II), TV Shows (Friends, Scrubs, ER, VIP) and dozens of National Commercials. An award-winning Educator (Hart Vision Award – CA Charter Teacher of the Year), he also serves as an Arts Education, Media Literacy, Digital Citizenry and Financial Literacy advocate throughout California. He produced the award-winning film Shakespeare High with Val Kilmer, Richard Dreyfuss, Kevin Spacey and Mare Winningham (Showtime).",
    group: "board",
  },
  {
    id: nanoid(),
    fullName: "John Becerra",
    bio:
      "John Becerra owns and manages Integrity Financial Services, a financial consultancy. He formerly served as a financial consultant for John Hancock Financial Advisors and then for Lincoln Financial Advisors. John also founded Sincerus Solutions, a staffing and search firm where he currently serves as a partner and board member. John attended Moorpark College and Pierce College and majored in psychology and business, respectively.",
    group: "board",
  },
  {
    id: nanoid(),
    fullName: "Val Kilmer",
    bio: `<p>Val Kilmer is one of the most prolific actors of his generation. From his more recent work as a career military officer in David Mamet’s Spartan, back through his work with Oliver Stone in The Doors and Michael Mann in Heat, to his early work in Top Gun, Kilmer has worked with many of film’s most respected directors and actors. Kilmer was the youngest student ever admitted to the drama department at Juilliard, Kilmer made his feature debut in the comedy Top Secret!, which he followed up with Real Genius and his breakout role as the Iceman in Tony Scott’s Top Gun, opposite Tom Cruise.</p>
      <p>Other memorable roles include Jim Morrison in Oliver Stone’s The Doors, the title character in Batman Forever, Doc Holliday in Tombstone and Simon Templar in The Saint. His other starring roles include Michael Mann’s Heat, with Robert De Niro and Al Pacino; True Romance, directed by Tony Scott; Ron Howard’s Willow; At First Sight; and Thunderheart.</p>`,
    group: "board",
  },
  {
    id: nanoid(),
    fullName: "Neil Nylander",
    bio:
      "Neil Nylander is CTO and Board Member of SparkRise, and the former CTO for Flaggpole, Inc. Neil brings over twenty years of Technology experience serving a wide variety of clients at Razorfish, a national digital agency and consulting firm (Microsoft, Nike, DHL, Washington Mutual, Kraft) and then co-founder of LiveAreaLabs, a digital agency purely focused on creating flagship e-commerce experiences for luxury and lifestyle brands (Adidas Outdoor, Burton, DVF, Ellen Degeneres, Fender, Leatherman, LUSH Cosmetics, Maui Jim, Movado, Panasonic, Smith Optics, Under Armour, Urban Decay, and World Vision).  ",
    group: "board",
  },
  {
    id: nanoid(),
    fullName: "Phil Lauria",
    bio:
      "Phil is a retail marketing, trade planning and revenue management expert who has built his reputation on driving outcomes for large retail facing organizations. Following his career at Kraft Foods, Phil focused on developing business strategy and strategic planning. He is the founder of the RORI Institute (Return on Retail Investment), a think-tank of brand and digital marketers. He’s also a managing partner at Optimization Partners helping organizations solve strategic data management and analytics challenges relating to sales, business and customer planning.",
    group: "board",
  },
  {
    id: nanoid(),
    fullName: "Ryan Myers",
    bio:
      "Ryan Myers is the CEO and Board Member of SparkRise. SparkRise acquired Flaggpole, Inc., which Ryan was the CEO of, as part of a recent merger/acquisition in 2020. Ryan has previously held the roles of VP of marketing for a financial company, VP of business development and marketing for a SaaS company, and spent 8 years working for the American Cancer Society in the nonprofit sector. Ryan has elevated companies to receive awards such as Inc 500, Fastest Growing Company, and Startup of the Year in Washington State.",
    group: "board",
  },
  {
    id: nanoid(),
    fullName: "Zack Sztanyo",
    bio:
      "Zack Sztanyo is an actor known for Anatomy of a Breakup (2017), and Rook (2020). He is also an entrepreneur, and managing member of Three Angels Investments. Zack comes from a background in logistics and manufacturing out of Detroit, Michigan and recently helped launch the non-profit, “No More Lost Souls” which delivered over 40,000lbs of supplies to help disaster areas affected by Hurricane Dorian in the Bahamas. Zack is thrilled to be a part of the SparkRise Board and help drive positive change in communities through this game-changing cause marketing strategy. ",
    group: "board",
  },
  {
    id: nanoid(),
    fullName: "Mark Duplass",
    bio: `<p>
      Mark Duplass is an award-winning actor, filmmaker and producer. He has written, directed and produced several acclaimed independent and studio films with his brother Jay, such as “The Puffy Chair,” “Cyrus,” and “Jeff, Who Lives at Home.” In 2014, Mark and Jay created the critically acclaimed television series “Togetherness” for HBO in which Mark also starred. They also released the docuseries sensation “Wild Wild Country” on Netflix in 2018, for which they won an Emmy. In early 2020, the Duplass Brothers signed a first-look deal with HBO for whom they are currently producing the anthological series “Room 104.” Upcoming projects include “Horse Girl” starring Alison Brie, which will be released on Netflix in February of 2020.
    </p>
      
      <p>
        Mark has also starred in some of the most critically lauded independent projects of the last decade. His credits include: Amazon’s hit show ‘Goliath”, Jason Reitman’s film “Tully,” Alexander Lehmann’s romantic drama “Blue Jay,” Lynn Shelton’s Gotham-award winning “Your Sister’s Sister,” Charlie McDowell’s sci-fi romance “The One I Love,” Patrick Brice’s horror-comedies “Creep” and “Creep 2,” Colin Trevorrow’s Independent Spirit award-winning “Safety Not Guaranteed,” the Sundance sensation “Humpday” and Katherine Bigelow’s Oscar-winning “Zero Dark Thirty.” He most recently produced and starred in the Netflix original film “Paddleton” with Ray Romano, as well as starred alongside Jennifer Aniston and Reese Witherspoon in Apple’s “The Morning Show.”
      </p>`,
    group: "advisor",
  },
  {
    id: nanoid(),
    fullName: "Ronnie Planalp",
    bio: `<p>
      Ronnie Planalp’s career has spanned senior roles in investment banking, publishing, the internet, recorded music, and film, with a significant focus on the international markets. She worked in investment banking at Goldman Sachs following her graduation from Columbia Business School. While living overseas in Hong Kong, Ronnie began her international media career and spent the next ten years there and in the UK in various business development and strategy roles for companies including Time Warner, Excite Europe, and EMI Recorded Music.
    </p>
    <p>
      Ronnie is a founder and producer at Clear Eye Productions, a film production company focused on documentaries. She has produced two award-winning films: They Came to Play and Shakespeare High. As a theater producer on Broadway and London’s West End, Ronnie’s credits as producer and investor include the Tony Award winner Exit the King, Elling, The Misanthrope, Legally Blond, Mountaintop, The Heidi Chronicles, The Glass Menagerie, If/Then, Here Lies Love and Tina: A Musical. Her recent film credits as executive producer include House of Z and Split.
    </p>
    <p>
      Ronnie was appointed by President Obama in March 2014 to serve on the Presidential Advisory Committee on the Arts for the John F. Kennedy Center for the Performing Arts.
      She co-chaired Senator Kirsten Gillibrand’s committee for her first congressional run in 2006 and serves on her National Finance Committee. In 2016, she was asked to join the advisory council for TEDx, the grassroots initiative born out of the umbrella organization TED (Technology, Entertainment, Design) known by the mission statement
      “Ideas Worth Spreading”. Ronnie is a vice president of the board of Film at Lincoln Center where she chairs the governance committee and serves on the executive committee. She also serves as a Trustee of Lincoln Center for the Performing Arts and as an Advisory Board member for the Tamer Center for Social Enterprise at Columbia Business School.
    </p>`,
    group: "advisor",
  },
  {
    id: nanoid(),
    fullName: "Dominic Kalms",
    bio: `<p>
      Dominic Kalms is a venture backed entrepreneur, philanthropist, and nonprofit leader with an expertise in impact technology companies, nonprofits, and charitable fundraising. He has raised over $50M in venture and philanthropic capital during his career and has been featured in an array of media publications (e.g. Forbes, CBS News, Entrepreneur, BBC World News, Yahoo News etc.) where he discusses philanthropy and impact. He was most recently featured on the front page of Entrepreneur Magazine, as well as in the Goldman Sachs Philanthropy web series where he discussed the growth of digital philanthropy.
    </p>
    <p>
      Dominic is also a well known public speaker on philanthropy and has spoken at many global forums, including at the Nexus Global Summit, Social Enterprise Summit, TechCrunch’s Global Ventures Summit, PxP Conference on Conscious Capitalism, NOVUS at the United Nations, The Capital One Stories of Impact Summit, Intergen Family Office Conference, TEDx etc.
    </p>
    <p>
      In his daily role Dominic is the Founder & President of GVNG, which Forbes called “One of the most revolutionary platforms in the social impact space today.” Dominic also sits on the board of the nationally recognized Child Safety Pledge, and The MINDS Foundation.
    </p>`,
    group: "advisor",
  },

  {
    id: nanoid(),
    fullName: "Jon Abeles",
    bio: `<p>
      Dr Abeles holds degrees in Pharmacology and Medicine from the University of Birmingham (UK). He had a career in the pharmaceutical industry (Sterling Drug (UK); Pfizer (US); USV Pharma (US) before becoming the first full time biopharma analyst on Wall St (Kidder Peabod)) with an MD, in 1975. He started his consulting practice (MedVest Group) and his family office (Northlea Partners) in 1980 in New York.
      He has been active in promulgating, directing, promoting, founding, financing, investing in and advising a large retinue of medical (biopharma; devices; diagnostics; some services) and some other non-medical companies, with many emerging as public entities and/or being acquired.
    </p>
    <p>
      He is an active supporter of charities and a sponsor of and investor in the arts.
    </p>`,
    group: "advisor",
  },

  {
    id: nanoid(),
    fullName: "Eliana Esteves-Higgins",
    bio: `<p>
      Eliana Esteves-Higgins is a native of São Paulo, Brazil.  She graduated with honors from UCLA with a PhD in neuropsychology. She practiced in her field for many years, focusing mainly on neuropsychological assessments. After seeing way too much suffering in the world, she decided to take a long break from the mental health world to enjoy a life full of blessings from above and focus on raising her son with her husband, Tim Higgins, a beautiful soul and brilliant NASA rocket scientist.  Eliana speaks various languages and loves traveling the world. She especially loves being in her homeland with family and friends. Eliana joined the SparkRise advisory board due to her passion to help communities and help alleviate suffering across the globe.
    </p>`,
    group: "advisor",
  },

  {
    id: nanoid(),
    fullName: "Kairand Bellinger",
    bio: `Kevin Kairand Bellinger’s is an award-winning Community & Cultural Impact Events Consultant, Designer, and Director.  Co-Founder/CEO of Immerse Alliance LLC, and Co-Founder of the Tribal Nations Music Tour, Kairand is an expert at using entertainment, experiential programming, sports, and music productions as tools to impact communities, drive philanthropy, and support community-based projects and important causes around the world. Kairand has organized partnerships with hundreds of community organizations in the Southwest - including a 25-year partnership with the Tribal Nations of the Southwest. In 1997 he was the recipient of the Northern New Mexico 'Top Ten Individual making a difference' award for his Founding and leadership of the 'Harambe Performing Arts and Education Center' partnering with over 150 community organization partners, producing in a 7-year span over 200 events engaging over 300,000 people in New Mexico.  Kairand worked as the national lead volunteer for the Lady Gaga Foundation 'Born This Way Foundation' 47 city community festival the 'Born Brave Bus Festival Tour', which led to partnering with national Connected Learning Alliance Leaders. In 2015, Kairand created the RAAVOLUTION (RAA Productions), Model. From 2015 to 2019, Kairand produced over 250 Events, mentored over 60 young adults, and engaged over 250,000 people through his community events, concerts, and RAAVOLUTION Festivals. From 2019 to 2020 he partnered with the cultural impact Band 'Tonight's Sunshine' to co-produce over 90 community music events and projects using celebration to support artists, communities, and causes.`,
    group: "advisor",
  },
];
