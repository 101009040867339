import React from "react";
import Img from "gatsby-image";
import PropTypes from "prop-types";
import cx from "classnames";
import MediaQuery from "react-responsive";

const Profile = ({ name, description, childImageSharp, odd }) => {
  const sectionClass = cx([
    "py-8 lg:py-12",
    { "bg-light-purple": !odd },
    { "bg-light-gray": odd },
  ]);

  const wrapClass = cx([
    "container flex flex-col overflow-hidden",
    { "lg:flex-row-reverse": !odd },
    { "lg:flex-row": odd },
  ]);

  const imageWrapClass = cx([
    "lg:w-1/3 flex-shrink-0",
    { "lg:pl-8": !odd },
    { "lg:pr-8": odd },
  ]);

  return (
    <section
      className={sectionClass}
      id={name.split(" ").join("_").toLowerCase()}
    >
      <div className={wrapClass}>
        {childImageSharp && (
          <div
            className={imageWrapClass}
            data-sal="slide-up"
            data-sal-delay="200"
            data-sal-easing="ease-out-cubic"
            style={{ "--sal-duration": "1s" }}
          >
            <Img fluid={childImageSharp.fluid} alt={name} />
          </div>
        )}
        <div className="lg:w-2/3 flex flex-col justify-center">
          {name && (
            <h2
              className="c-h4 text-purple mb-1 mt-4 lg:mb-2 lg:mt-0"
              data-sal={odd ? "slide-left" : "slide-right"}
              data-sal-delay="200"
              data-sal-easing="ease-out-cubic"
              style={{ "--sal-duration": "1s" }}
            >
              {name}
            </h2>
          )}
          {description && (
            <>
              <MediaQuery minWidth={1024}>
                <div
                  className="prose max-w-none"
                  data-sal={odd ? "slide-left" : "slide-right"}
                  data-sal-delay="500"
                  data-sal-easing="ease-out-cubic"
                  style={{ "--sal-duration": "1s" }}
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </MediaQuery>
              <MediaQuery maxWidth={768}>
                <div
                  className="prose max-w-none"
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </MediaQuery>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

Profile.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  childImageSharp: PropTypes.object,
  odd: PropTypes.bool,
};

export default Profile;
