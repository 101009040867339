import React from "react";
import { graphql, useStaticQuery } from "gatsby";
// import Img from "gatsby-image";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Profile from "../components/about/profile";
import { teamData } from "../data/team";

function AboutPage() {
  const boardData = teamData.filter((member) => member.group.includes("board"));
  const advisorsData = teamData.filter((member) =>
    member.group.includes("advisor")
  );

  const { teamImages } = useStaticQuery(graphql`
    query {
      teamImages: allFile(filter: { name: { glob: "board-*" } }) {
        images: edges {
          imageData: node {
            name
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);

  // Merge member data with gatsby image data
  const boardMembers = [];
  boardData.forEach((member) => {
    teamImages.images.forEach(({ imageData }) => {
      const fullNameSplit = member.fullName.split(" ");
      const fullNameFound = fullNameSplit.every((word) =>
        imageData.name.includes(word.toLowerCase())
      );
      if (fullNameFound) {
        boardMembers.push({ ...member, ...imageData });
      }
    });
  });

  const advisors = [];
  advisorsData.forEach((member) => {
    teamImages.images.forEach(({ imageData }) => {
      const fullNameSplit = member.fullName.split(" ");
      const fullNameFound = fullNameSplit.every((word) =>
        imageData.name.includes(word.toLowerCase())
      );
      if (fullNameFound) {
        advisors.push({ ...member, ...imageData });
      }
    });
  });

  console.log(boardMembers);

  return (
    <Layout>
      <SEO keywords={[`sparkrise`, `about`]} title="About" />

      <article className="mt-12">
        <heading className="container">
          <h2 className="c-h2 text-center text-orange">Board of Directors</h2>
        </heading>

        {boardMembers.map((member, index) => {
          return (
            <Profile
              key={member.name}
              odd={index % 2 === 0}
              name={member.fullName}
              description={member.bio}
              childImageSharp={member.childImageSharp}
            />
          );
        })}
      </article>

      <article className="mt-24 space-y-12 lg:space-y-36">
        <heading className="container mt-24">
          <h2 className="c-h2 text-center text-orange">
            SparkRise Strategic Advisors
          </h2>
        </heading>

        {advisors.map((member, index) => {
          return (
            <Profile
              key={member.name}
              odd={index % 2 === 0}
              name={member.fullName}
              description={member.bio}
              childImageSharp={member.childImageSharp}
            />
          );
        })}
      </article>
    </Layout>
  );
}

export default AboutPage;
